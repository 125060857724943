import React, { useMemo, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import moment from 'moment'
import toast from 'react-hot-toast'

function copyTableToClipboard (id) {
  const table = document.getElementById(id)
  let range, selection

  if (document.createRange && window.getSelection) {
    range = document.createRange()
    selection = window.getSelection()
    range.selectNodeContents(table)
    selection.removeAllRanges()
    selection.addRange(range)
  } else if (document.body.createTextRange) {
    range = document.body.createTextRange()
    range.moveToElementText(table)
    range.select()
  }

  document.execCommand('copy')
  window.getSelection().removeAllRanges()
  toast.success('Skopiowano tabelę do schowka')
}

function App () {
  const [file, setFile] = useState(null)
  const [startPage, setStartPage] = useState('')
  const [result, setResult] = useState(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [sortConfig, setSortConfig] = useState({ key: 'institution_name', direction: 'ascending' })
  const requestSort = key => {
    let direction = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  const sortedData = (input) => {
    const sorted = [...input]
    if (sortConfig.key !== null) {
      sorted.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sorted
  }

  const renderArrow = column => {
    if (sortConfig.key === column) {
      return sortConfig.direction === 'ascending' ? <span>&uarr;</span> : <span>&darr;</span>
    }
    return null
  }

  const getResultsToDisplay = useMemo(() => {
    if (Array.isArray(result)) {
      return result.filter(loan => {
        const startAmount = Number(loan.initial_amount.replace(' PLN', '').replace('.', ''))
        const endAmount = Number(loan.end_amount.replace(' PLN', '').replace('.', ''))

        const dateParts = loan.start_date.split('.')
        const startDate = dateParts[2] + '.' + dateParts[1] + '.' + dateParts[0]

        return moment(startDate).isAfter(moment('2011.12.18')) && startAmount >= endAmount
      })
    } else {
      return []
    }
  }, [result])

  const summarizeCreditValues = (credits) => {
    const summaryMap = new Map()

    credits.forEach(({ institution_name, end_amount }) => {
      const endAmount = Number(end_amount.replace(' PLN', '').replace('.', ''))
      summaryMap.set(institution_name, (summaryMap.get(institution_name) || 0) + endAmount)
    })

    return [...summaryMap].map(([name, value]) => ({ name, value }))
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }

  const handleStartPageChange = (e) => {
    setStartPage(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setResult(null)

    if (!file || !startPage) {
      setError('Wybierz plik i wskaż stronę początkową.')
      return
    }

    setLoading(true)

    const formData = new FormData()
    formData.append('file', file)
    formData.append('start_page', startPage)

    try {
      const response = await axios.post('https://api.royalspa-poznan.pl/process_pdf', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      setResult(response.data)
      setError('')
      setLoading(false)
    } catch (error) {
      setError('Wystąpił błąd podczas przetwarzania pliku')
      setResult(null)
      setLoading(false)
    }
  }

  const isButtonDisabled = loading || !file || !startPage

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <div>
          <input type='file' onChange={handleFileChange} />
        </div>
        <div>
          <span>Strona początkowa: </span>
          <input type='text' value={startPage} onChange={handleStartPageChange} />
        </div>
        <button type='submit' disabled={isButtonDisabled}>Szukaj</button>
      </Form>
      {error && <Error>{error}</Error>}
      {loading && (
        <LoaderWrapper>
          <Loader className='loader' />
        </LoaderWrapper>
      )}
      {Array.isArray(result) && result.length > 0 && (
        <>
          <TableWrapper>
            <div style={{ width: '100%' }}>
              <CopyButtonWrapper>
                <button onClick={() => copyTableToClipboard('main-table')}>Skopiuj tabelę</button>
              </CopyButtonWrapper>
              <Table id='main-table' style={{ width: '100%' }}>
                <thead>
                <th onClick={() => requestSort('institution_name')}>Nazwa
                  instytucji {renderArrow('institution_name')}</th>
                <th onClick={() => requestSort('type')}>Rodzaj zobowiązania {renderArrow('type')}</th>
                <th onClick={() => requestSort('start_date')}>Data startu {renderArrow('start_date')}</th>
                <th onClick={() => requestSort('end_date')}>Data zamknięcia {renderArrow('end_date')}</th>
                <th onClick={() => requestSort('initial_amount')}>Kwota początkowa {renderArrow('initial_amount')}</th>
                <th onClick={() => requestSort('end_amount')}>Kwota zamknięcia {renderArrow('end_amount')}</th>
                </thead>
                <tbody>
                {sortedData(getResultsToDisplay).map(row => {
                  return (
                    <tr>
                      <td>{row.institution_name}</td>
                      <td>{row.type}</td>
                      <td>{row.start_date}</td>
                      <td>{row.end_date}</td>
                      <td>{row.initial_amount}</td>
                      <td>{row.end_amount}</td>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
            </div>
          </TableWrapper>
          <TableWrapper>
            <div>
              <CopyButtonWrapper>
                <button onClick={() => copyTableToClipboard('additional-table')}>Skopiuj tabelę</button>
              </CopyButtonWrapper>
              <Table id='additional-table'>
                <thead>
                <th>Nazwa instytucji</th>
                <th>Suma kwoty zamknięcia</th>
                </thead>
                <tbody>
                {summarizeCreditValues(getResultsToDisplay).map(el => (
                  <tr>
                    <td>{el.name}</td>
                    <td>{el.value} PLN</td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </div>
          </TableWrapper>
        </>
      )}
      {
        result && (result.length === 0 || !Array.isArray(result)) && !loading && (
          <NoResults>Brak wyników</NoResults>
        )
      }
    </Wrapper>
  )
}

export default App

const Wrapper = styled.div`
  padding: 40px 20px;
  max-width: 1440px;
  margin: 0 auto;

  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
  }

  .loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid black;
    animation: prixClipFix 2s linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg)
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
    }
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;

  input[type="text"] {
    width: 50px;
    margin-left: 10px;
    text-align: center;
    font-size: 18px;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    border: 1px solid #CECECE;
    padding: 5px;

    &:focus {
      border: 1px solid #40679E;
    }
  }

  input[type="file"] {
    width: 100%;
    font-size: 18px;
    margin-bottom: 10px;
  }

  input[type="file"]::file-selector-button {
    background-color: #fff;
    border: 1px solid #40679E;
    padding: 8px 22px;
    cursor: pointer;
    margin-right: 16px;
    color: #40679E;
    transition: all .3s;
    font-size: 16px;

    &:hover {
      background: #40679E;
      color: #fff;
    }
  }

  button {
    background-color: #fff;
    border: 1px solid #40679E;
    padding: 8px 22px;
    cursor: pointer;
    margin-top: 16px;
    color: #40679E;
    transition: all .3s;
    font-size: 16px;
    width: 300px;

    &:hover {
      background: #40679E;
      color: #fff;
    }
  }
`

const Loader = styled.span`
  display: block;
  margin: 10px 0;
  min-width: 20px;
  min-height: 20px;
`

const TableWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
`

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);

  thead {
    background-color: #40679E;
    color: #fff;
  }

  th {
    cursor: pointer;
  }

  tr:nth-of-type(even) {
    background-color: #f2f2f2;
  }

  td, th {
    font-size: 14px;
    text-align: left;
    padding: 5px 15px;
    border: 1px solid #ccc;
    min-width: 150px;
  }
`

const CopyButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    background-color: #fff;
    border: 1px solid #40679E;
    padding: 3px 5px;
    cursor: pointer;
    margin-bottom: 8px;
    color: #40679E;
    transition: all .3s;
    font-size: 14px;

    &:hover {
      background: #40679E;
      color: #fff;
    }
  }
`

const LoaderWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Error = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cc0000;
`

const NoResults = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #444;
`
